import React from "react";
import theme from "theme";
import { Theme, Em, Image, Box, Text, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Veritas Legal Insights
			</title>
			<meta name={"description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:title"} content={"Veritas Legal Insights"} />
			<meta property={"og:description"} content={"Чесність і прозорість на кожному кроці"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Veritas Legal Insights
			</Override>
			<Override slot="text4" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box1" />
			<Override slot="link3" />
			<Override slot="text3" />
			<Override slot="link4" />
			<Override slot="icon" />
			<Override slot="box3" />
			<Override slot="linkBox" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text1">
				VERITAS LEGAL INSIGHTS
			</Override>
			<Override slot="text" text-transform="uppercase">
				Де ваші юридичні потреби задовольняються з неперевершеною відданістю та досвідом
			</Override>
			<Override slot="text2">
				<Em>
					Чесність і прозорість на кожному кроці
				</Em>
			</Override>
		</Components.Hero>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12:31:02.888Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-2.jpg?v=2024-04-04T12%3A31%3A02.888Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="40%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Ласкаво просимо!
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ласкаво просимо до Veritas Legal Insights, де ваші юридичні потреби задовольняються з неперевершеною відданістю та досвідом. Ми більше, ніж просто юридична фірма - ми ваші партнери в навігації у складному світі права. Наша команда прагне надавати вам чіткі та практичні поради, які мають відчутний вплив на ваше особисте та професійне життя. Ми віримо в силу взаєморозуміння та цінність довіри, і ми супроводжуємо наших клієнтів на їхніх юридичних шляхах чесно та точно.
				</Text>
				<Button
					type="link"
					href="/about"
					text-align="center"
					text-decoration-line="initial"
					margin="2rem 0px 0px 0px"
				>
					Дізнатися більше
				</Button>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="40%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Персоналізована увага
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Кожен випадок унікальний, як і наш підхід. Ми гарантуємо, що ваші проблеми будуть почуті, а ваші потреби будуть задоволені з максимальною увагою та індивідуальною стратегією.{"\n"}
					<br />
					<br />
					<Em>
						{"\n"}Наш пріоритет - це чіткість, простота та зрозумілість. Ми демістифікуємо юридичний жаргон, роблячи закон доступним і зрозумілим.
					</Em>
				</Text>
			</Box>
			<Box
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12:31:02.882Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/2-1.jpg?v=2024-04-04T12%3A31%3A02.882Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12:31:02.878Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/3-3.jpg?v=2024-04-04T12%3A31%3A02.878Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="40%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Етична практика
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Чесність лежить в основі всього, що ми робимо. Ви можете бути впевнені, що ми завжди діятимемо у ваших інтересах. Обрати Верітас Легал Інсайтс означає обрати партнера, який цінує ваш спокій так само, як і вирішення ваших юридичних питань.{"\n"}
					<br />
					<br />
					{"\n"}Ваш успіх - це наш успіх. Ми використовуємо наші глибокі юридичні знання для досягнення результатів, які дійсно важливі для вас.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="40%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Наші стовпи міцності
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Veritas Legal Insights - це не лише наша юридична експертиза, а й люди, які втілюють нашу місію в життя.{" "}
					<br />
					Ми слухаємо, розуміємо та діємо зі співчуттям на кожному кроці.{"\n"}
					<br />
					<br />
					Прагнення до досконалості: наші юристи та консультанти не просто юристи - вони невтомні захисники прав та успіху наших клієнтів.{"\n"}
					<br />
					<br />
					Інноваційні стратегії: ми поєднуємо перевірені юридичні стратегії з інноваційними підходами для адаптації до постійно мінливого правового ландшафту.{"\n"}
					<br />
					<br />
					{"\n"}Разом ми формуємо динамічну команду, яка прагне змінити на краще життя тих, кому ми служимо.
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
				<Button
					type="link"
					href="tel:+420222513719"
					text-align="center"
					text-decoration-line="initial"
					margin="2rem 0px 0px 0px"
				>
					Зателефонувати
				</Button>
			</Box>
			<Box
				width="60%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12:31:02.881Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/660e76383f24780021f5a53f/images/4-3.jpg?v=2024-04-04T12%3A31%3A02.881Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="link1">
				0661100277
			</Override>
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text" />
		</Components.Contacts>
		<Components.Header>
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});